import Button from "components/Button";
import { IoMdCheckmark } from "react-icons/io";
import styled from "styled-components";

export const EXERCISE_OPEN_DURATION_SEC = 0.3;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Scroll = styled.div`
  flex: 1;

  overflow-x: hidden;
  overflow-y: auto;

  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;

  font-size: 15px;
  line-height: 1.4;
  white-space: pre-wrap;
  text-align: left;

  color: ${(props) => props.theme.colors.text}99;

  strong {
    font-weight: 400;
    color: ${(props) => props.theme.colors.warning};
  }
`;

const Footer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  padding: 16px 12px;
`;

const ConfirmModal = (props: { text: string; onConfirm?: () => void }) => {
  const { onConfirm } = props;

  const confirm = () => {
    onConfirm?.();
  };

  //
  // RENDER
  //
  return (
    <Wrapper>
      <Scroll>{props.text}</Scroll>

      <Footer>
        <Button
          icon={IoMdCheckmark}
          label="Potvrdit"
          onClick={confirm}
          warning
        />
      </Footer>
    </Wrapper>
  );
};

export default ConfirmModal;
