/* eslint-disable react-hooks/exhaustive-deps */

import {
  DataOverride,
  LOCAL_STORAGE_DATA,
  LOCAL_STORAGE_DATA_OVERRIDE,
  deleteFromLocalStorage,
  generateUniqueId,
  getMealNameFromId,
  getValidToken,
  googleDrive_downloadJsonFile,
  googleDrive_updateJsonFile,
  googleSignIn,
  googleSignOut,
  loadFromLocalStorage,
  saveToLocalStorage,
} from "utils";
import {
  DataType,
  DayType,
  PopUpType,
  PopUpTypeEnum,
  TimerType,
  WeightHistoryItemType,
  WeightType,
  WorkoutPlanType,
} from "types";
import React, {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import ConfirmModal from "components/ConfirmModal";
import ConfirmResetData from "components/ConfirmResetData";
import OfflineModePopUp from "components/OfflineModePopUp";

interface GlobalStorageType {
  data: DataType;
  timer: TimerType;
  popUps: PopUpType[];
  isOffline: boolean;
  isSignedIn: boolean;
  isLoading: boolean;
  isUploading: boolean;
}

interface GlobalStorageContextType {
  state: GlobalStorageType;
  setState: Dispatch<SetStateAction<GlobalStorageType>>;
}

export enum StorageType {
  "googleDrive" = "googleDrive",
  "localStorage" = "localStorage",
}

//
// CONTEXT
//
const GlobalStorageContext = createContext<
  GlobalStorageContextType | undefined
>(undefined);

//
// PROVIDER
//
const DEFAULT_STATE: GlobalStorageType = {
  data: undefined,
  timer: undefined,
  popUps: [],
  isOffline: undefined,
  isSignedIn: false,
  isLoading: false,
  isUploading: false,
};

export const GlobalStorageProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [state, setState] = useState<GlobalStorageType>(DEFAULT_STATE);

  return (
    <GlobalStorageContext.Provider value={{ state, setState }}>
      {children}
    </GlobalStorageContext.Provider>
  );
};

//
// HOOK
//
export const useGlobalStorage = () => {
  const { state, setState } = useContext(GlobalStorageContext);

  const isOfflineRef = useRef(state.isOffline);

  useEffect(() => {
    isOfflineRef.current = state.isOffline;
  }, [state.isOffline]);

  useEffect(() => {
    if (state?.isLoading) {
      fns.closePopUps();
    }
  }, [state?.isLoading]);

  // CAREFUL - ONLY FILES CREATED VIA API WORK, DON'T KNOW WHY
  const fileId = "1RryzJDEwV0sck8cUk1XiIJgrfb9j_p3p"; // TODO - MOVE TO ENV + CREATE PROD + DEV FILE IN GOOGLE DRIVE

  //
  // PRIVATE FUNCS
  //
  const loadDataWrapper: (
    getData: () => Promise<DataType>
  ) => Promise<DataType> = async (getData) => {
    setState((prevState) => {
      return { ...prevState, data: undefined, isLoading: true };
    });

    let loadedData = null;

    try {
      loadedData = await getData();

      setState((prevState) => {
        return { ...prevState, data: loadedData, isLoading: false };
      });
    } catch (error) {
      fns.handleError({
        title: "Google Drive download failed",
        error,
      });
    } finally {
      setState((prevState) => {
        return { ...prevState, isLoading: false };
      });

      return loadedData;
    }
  };

  const saveDataWrapper: (
    saveData: () => Promise<void>
  ) => Promise<void> = async (saveData) => {
    setState((prevState) => {
      return { ...prevState, isUploading: true };
    });

    try {
      await saveData();

      setState((prevState) => {
        return { ...prevState, isUploading: false };
      });
    } catch (error) {
      fns.handleError({
        title: "Google Drive upload failed",
        error,
      });
    } finally {
      setState((prevState) => {
        return { ...prevState, isUploading: false };
      });
    }
  };

  // Google Drive
  const loadDataFromGoogleDrive = () => {
    const getData: () => Promise<DataType> = async () => {
      const accessToken = await getValidToken();
      const downloadedJson = await googleDrive_downloadJsonFile(
        accessToken,
        fileId
      );
      console.info("Data downloaded from Google Drive", downloadedJson);
      return downloadedJson;
    };
    return loadDataWrapper(getData);
  };

  const saveDataToGoogleDrive = (data: DataType) => {
    const saveData: () => Promise<void> = async () => {
      const accessToken = await getValidToken();
      await googleDrive_updateJsonFile(accessToken, fileId, data);
      console.info("Data uploaded to Google Drive");
    };
    return saveDataWrapper(saveData);
  };

  // Local Storage
  const loadDataFromLocalStorage = async () => {
    const getData: () => Promise<DataType> = async () => {
      const downloadedJson = await loadFromLocalStorage<DataType>(
        LOCAL_STORAGE_DATA
      );
      console.info("Data loaded from local storage", downloadedJson);
      return downloadedJson;
    };
    return loadDataWrapper(getData);
  };

  const saveDataToLocalStorage = async (data: DataType) => {
    const saveData: () => Promise<void> = async () => {
      await saveToLocalStorage(LOCAL_STORAGE_DATA, data);
      console.info("Data stored in local storage");
    };
    return saveDataWrapper(saveData);
  };

  //
  // PUBLIC FUNCS
  //
  const fns = {
    //
    // Google Sign In / Out
    //
    signIn: async (overrideIsOffline?: boolean) => {
      try {
        const isOffline =
          overrideIsOffline === undefined
            ? isOfflineRef.current
            : overrideIsOffline;
        let isSignedIn = false;

        if (!isOffline) {
          const token = await googleSignIn();
          isSignedIn = !!token;
        } else {
          isSignedIn = true;
        }

        if (isSignedIn) {
          setState((prevState) => {
            return { ...prevState, isSignedIn };
          });

          fns.initData();
        } else {
          throw new Error("Token not returned.");
        }
      } catch (error) {
        fns.handleError({
          title: "Google Sign In Failed",
          error,
        });
      }
    },

    signOut: async () => {
      if (!isOfflineRef.current) {
        googleSignOut();
      }

      setState((prevState) => {
        return { ...prevState, isSignedIn: false };
      });
    },

    //
    // Init data
    //
    initData: async () => {
      const dataOverride = loadFromLocalStorage(LOCAL_STORAGE_DATA_OVERRIDE);

      if (dataOverride) {
        const isOffline = isOfflineRef.current;

        // COPY DATA FROM LOCAL STORAGE TO (CURRENTLY ACTIVE) GOOGLE DRIVE
        if (
          !isOffline &&
          dataOverride === DataOverride.from_localStorage_to_googleDrive
        ) {
          const data = await fns.loadData({
            storageType: StorageType.localStorage,
          });

          console.log("data", data);

          await fns.saveData({
            editedData: data,
            storageType: StorageType.googleDrive,
          });
        }

        // COPY DATA FROM GOOGLE DRIVE TO (CURRENTLY ACTIVE) LOCAL STORAGE
        else if (
          isOffline &&
          dataOverride === DataOverride.from_googleDrive_to_localStorage
        ) {
          /**
           * this is handled in the isOffline switch
           * - at the time of switching from online to offline, google drive data is already acquired
           * - fetching google drive data now would require google login, which is disabled in offline mode
           * -> handle copying data from local storage to google drive in isOffline change immediately (while the token is active)
           */
        }

        deleteFromLocalStorage(LOCAL_STORAGE_DATA_OVERRIDE);
      } else {
        await fns.loadData();
      }
    },

    //
    // Save (based on online / offline mode)
    //
    loadData: async (params?: { storageType?: StorageType }) => {
      const type: StorageType =
        params?.storageType ??
        (isOfflineRef.current
          ? StorageType.localStorage
          : StorageType.googleDrive);

      switch (type) {
        case StorageType.localStorage:
          return loadDataFromLocalStorage();
        case StorageType.googleDrive:
          return loadDataFromGoogleDrive();
      }
    },

    saveData: async (params?: {
      editedData?: DataType;
      storageType?: StorageType;
    }) => {
      const data = params?.editedData ?? state.data;

      const type: StorageType =
        params?.storageType ??
        (isOfflineRef.current
          ? StorageType.localStorage
          : StorageType.googleDrive);

      switch (type) {
        case StorageType.localStorage:
          return saveDataToLocalStorage(data);
        case StorageType.googleDrive:
          return saveDataToGoogleDrive(data);
      }
    },

    //
    // Offline Mode
    //
    setIsOfflineRaw: (isOffline: boolean) => {
      setState((prevState) => {
        return {
          ...prevState,
          isOffline: isOffline ?? false,
        };
      });
    },

    setOfflineMode: (isOffline: boolean) => {
      const popUpId = "offline-mode-popup";

      fns.showPopUp({
        id: popUpId,
        title: isOffline ? "Zapnout offline mode" : "Vypnout offline mode",
        content: <OfflineModePopUp isOffline={isOffline} popUpId={popUpId} />,
      });
    },

    //
    // Global Timer
    //
    setTimer: (timer: TimerType) => {
      setState((prevState) => {
        return { ...prevState, timer };
      });
    },

    //
    // Errors
    //
    handleError: (params: { title: string; error: Error }) => {
      const { title, error } = params;

      console.error(`${title}:`, error);
      fns.showPopUpError({
        title,
        message: error.message,
      });
    },

    //
    // Pop-ups
    //
    showPopUp: (popUp: Partial<PopUpType>) => {
      const newPopUp: PopUpType = { id: generateUniqueId(), ...popUp };

      setState((prevState) => {
        return {
          ...prevState,
          popUps: !prevState.popUps
            ? [newPopUp]
            : [newPopUp, ...prevState.popUps],
        };
      });
    },

    showPopUpError: (params: { title: string; message: string }) => {
      const newPopUp: Partial<PopUpType> = {
        type: PopUpTypeEnum.error,
        title: "Error",
        content: (
          <div>
            <div>{params.title}</div>
            <br />
            <div>{params.message}</div>
          </div>
        ),
      };
      fns.showPopUp(newPopUp);
    },

    closePopUp: (id: string) => {
      setState((prevState) => {
        return {
          ...prevState,
          popUps: prevState.popUps?.filter((p) => p.id !== id),
        };
      });
    },

    closePopUps: () => {
      setState((prevState) => {
        return {
          ...prevState,
          popUps: undefined,
        };
      });
    },

    //
    // DATA
    //
    resetCurrentPlan: () => {
      const resetPopUpId = `reset-current-plan-confirmation`;

      fns.showPopUp({
        id: resetPopUpId,
        title: "Resetovat aktuální plán?",
        noPadding: true,
        content: (
          <ConfirmResetData onConfirm={() => fns.closePopUp(resetPopUpId)} />
        ),
      });
    },

    resetCurrentPlanRaw: (params: {
      newDateStart: string;
      newDateEnd: string;
      newText: string;
    }) => {
      let updatedPlan: WorkoutPlanType = state.data?.workoutPlans[0];

      updatedPlan = {
        ...updatedPlan,

        dates: {
          start: params.newDateStart || "??",
          end: params.newDateEnd || "??",
        },

        text: params.newText || "??",

        weight: {
          startKg: updatedPlan?.weight?.endKg,
          endKg: undefined,
        },

        days: updatedPlan?.days?.map((d) => {
          return {
            ...d,

            isSickDay: false,

            exercises: d.exercises?.map((e) => {
              return {
                ...e,
                sets: e?.sets?.map((s) => {
                  return {
                    ...s,
                    isCompleted: false,
                  };
                }),
              };
            }),

            meals: d.meals?.map((m) => {
              return {
                ...m,
                options: m?.options?.map((o) => {
                  return { ...o, isCompleted: false };
                }),
                customOption: undefined,
              };
            }),

            supplements: d.supplements?.map((s) => {
              return {
                ...s,
                options: s?.options?.map((o) => {
                  return { ...o, isCompleted: false };
                }),
                customOption: undefined,
              };
            }),
          };
        }),
      };

      setState((prevState) => {
        const newState = {
          ...prevState,
          data: {
            ...prevState.data,
            workoutPlans: prevState.data.workoutPlans.map((p, i) =>
              i === 0 ? updatedPlan : p
            ),
          },
        };

        return newState;
      });
    },

    getDataAsString: () => {
      const lines = [];

      const plan = state?.data?.workoutPlans[0];

      if (plan) {
        const separatorLength = 20;

        const separatorMain = "/".repeat(separatorLength);
        const separatorSection = "=".repeat(separatorLength);
        const separatorSubSection = "-".repeat(separatorLength);
        const separatorSubSection2 = ".".repeat(separatorLength);

        const startKg = plan.weight?.startKg;
        const endKg = plan.weight?.endKg;
        const diffKg = startKg && endKg ? endKg - startKg : undefined;

        lines.push(`${plan.dates?.start}  -  ${plan.dates?.end}`);
        lines.push(``);
        lines.push(`${plan.text}`);

        lines.push(``);
        lines.push(separatorMain);
        lines.push(``);

        lines.push(`Počáteční váha: ${startKg ? `${startKg} kg` : ""}`);
        lines.push(`Konečná váha: ${endKg ? `${endKg} kg` : ""}`);
        lines.push(`Rozdíl: ${diffKg ? `${diffKg} kg` : ""}`);

        lines.push(``);
        lines.push(separatorMain);
        lines.push(``);
        lines.push(``);

        lines.push(separatorSection);
        lines.push(`PŘEHLED JÍDEL`);
        lines.push(separatorSection);
        lines.push(``);
        plan.mealSuggestions?.forEach((s) => {
          if (s) {
            lines.push(
              `${s.mealIds
                ?.map((id) => getMealNameFromId(id))
                .join(", ")
                .toUpperCase()}`
            );
            lines.push(separatorSubSection);
            lines.push(``);

            s.suggestions?.forEach((s, i) => {
              if (s) {
                lines.push(`Varianta ${i + 1}`);
                lines.push(separatorSubSection2);
                lines.push(s);
                lines.push(``);
                lines.push(``);
              }
            });

            lines.push(``);
            lines.push(``);
          }
        });

        plan.days?.forEach((day) => {
          lines.push(``);
          lines.push(``);
          lines.push(separatorSection);
          lines.push(`${day.name}`.toUpperCase());
          lines.push(separatorSection);
          lines.push(``);
          lines.push(``);

          if (day?.text?.length) {
            lines.push(day.text);
            lines.push(``);
          }

          lines.push(`Jídlo`.toUpperCase());
          lines.push(separatorSubSection);
          lines.push(``);

          day.meals?.forEach((m) => {
            if (m) {
              lines.push(`${m.name}`);
              lines.push(separatorSubSection2);

              if (m.text?.length) {
                lines.push(`(${m.text})`);
              }

              let isFilled = false;

              m.options
                ?.filter((o) => o.isCompleted)
                ?.forEach((o) => {
                  isFilled = true;
                  lines.push(o.ingredients?.join("\n"));
                  lines.push(``);
                });

              if (m.customOption?.isCompleted) {
                isFilled = true;
                lines.push(m.customOption.text);
                lines.push(``);
              }

              if (!isFilled) {
                lines.push(`?`);
                lines.push(``);
              }
            }
          });

          lines.push(``);
          lines.push(``);
          lines.push(`Suplementy`.toUpperCase());
          lines.push(separatorSubSection);
          lines.push(``);

          day.supplements?.forEach((s) => {
            if (s) {
              lines.push(`${s.name}`);
              lines.push(separatorSubSection2);

              if (s.text?.length) {
                lines.push(`(${s.text})`);
              }

              let isFilled = false;

              s.options
                ?.filter((o) => o.isCompleted)
                ?.forEach((o) => {
                  isFilled = true;
                  lines.push(o.ingredients?.join("\n"));
                  lines.push(``);
                });

              if (s.customOption?.isCompleted) {
                isFilled = true;
                lines.push(s.customOption.text);
                lines.push(``);
              }

              if (!isFilled) {
                lines.push(`?`);
                lines.push(``);
              }
            }
          });

          lines.push(``);
          lines.push(``);
          lines.push(`Trénink`.toUpperCase());
          lines.push(separatorSubSection);
          lines.push(``);

          if (day.exercises?.length) {
            day.exercises?.forEach((e) => {
              if (e) {
                lines.push(`${e.name}`);
                lines.push(separatorSubSection2);

                if (e.text?.length) {
                  lines.push(`${e.text}`);
                }

                if (e.sets?.length > 1) {
                  lines.push(
                    `Pauza: ${
                      e.pauseBetweenSetsMinutes ??
                      day.defaultPauseBetweenSetsMinutes
                    } min`
                  );
                }

                lines.push(``);

                e.sets?.forEach((set) => {
                  if (e.isCardio) {
                    lines.push(`${set.durationMinutes} min`);
                  } else {
                    lines.push(`${set.repCount}x - ${set.value ?? ""}`);
                  }
                });

                lines.push(``);
                lines.push(``);
              }
            });
          } else {
            lines.push(`REST DAY`);
            lines.push(``);
            lines.push(``);
          }
        });
      }

      const str = lines.join("\n");
      return str;
    },

    updateWeight: (params: { workoutId: string; newWeight: WeightType }) => {
      const { workoutId, newWeight } = params;

      setState((prevState) => {
        const newState = {
          ...prevState,
          data: {
            ...prevState.data,
            workoutPlans: prevState.data.workoutPlans.map((p) =>
              p.id === workoutId
                ? {
                    ...p,
                    weight: newWeight,
                  }
                : p
            ),
          },
        };

        return newState;
      });
    },

    updateWeightHistory: (params: {
      updatedWeights: WeightHistoryItemType[];
    }) => {
      const { updatedWeights } = params;

      setState((prevState) => {
        const newState = {
          ...prevState,
          data: {
            ...prevState.data,
            weightHistory: {
              ...(prevState.data.weightHistory || {}),
              weights: updatedWeights,
            },
          },
        };

        return newState;
      });
    },

    deleteFromWeightHistory: (params: {
      deleteWeight: WeightHistoryItemType;
      deleteIndex: number;
      closePopUpId?: string;
    }) => {
      const popUpId = `delete-weight-confirmation`;

      fns.showPopUp({
        id: popUpId,
        title: "Odstranit váhu?",
        noPadding: true,
        content: (
          <ConfirmModal
            text={`Smazat hodnotu ${params.deleteWeight?.weightKg} kg z ${params.deleteWeight.date}?`}
            onConfirm={() => {
              fns.updateWeightHistory({
                updatedWeights: state.data.weightHistory?.weights?.filter(
                  (w, i) => i !== params.deleteIndex
                ),
              });

              if (params.closePopUpId) {
                // close edit popup if deleting from edit modal
                fns.closePopUp(params.closePopUpId);
              }
              fns.closePopUp(popUpId);
            }}
          />
        ),
      });
    },

    updateDay: (params: {
      workoutId: string;
      dayId: string;
      newDay: DayType;
    }) => {
      const { workoutId, dayId, newDay } = params;

      setState((prevState) => {
        const newState = {
          ...prevState,
          data: {
            ...prevState.data,
            workoutPlans: prevState.data.workoutPlans.map((workout) =>
              workout.id === workoutId
                ? {
                    ...workout,
                    days: workout.days.map((day) =>
                      day.id === dayId ? newDay : day
                    ),
                  }
                : workout
            ),
          },
        };

        return newState;
      });
    },
  };

  return {
    data: state.data,
    timer: state.timer,
    popUps: state.popUps,
    isSignedIn: state.isSignedIn,
    isOffline: state.isOffline,
    isOfflineRef,
    isLoading: state.isLoading,
    isUploading: state.isUploading,
    fns,
  };
};
