import { GraphDataItem } from "types";

export const getFormattedDate = (
  date: string | Date,
  params?: {
    showWeekday?: boolean;
  }
): string => {
  const { showWeekday } = params || {};
  const parsedDate = typeof date === "string" ? new Date(date) : date;

  // Extract weekday separately if needed
  const weekday = showWeekday
    ? `<span class="weekday">${parsedDate.toLocaleDateString("cs-CZ", {
        weekday: "long",
      })}</span> `
    : "";

  // Format only day and month
  const formattedDate = parsedDate.toLocaleDateString("cs-CZ", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  return `${weekday}${formattedDate}`;
};

export const getCurrentDate = (): string => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0");
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const parseDate = (dateString: string): Date => {
  const [year, month, day] = dateString.split("-").map(Number);
  return new Date(year, month - 1, day); // Month is 0-based in JavaScript Date
};

export const sortAndFillDateGapsAsc = (
  graphDataArray: GraphDataItem[]
): GraphDataItem[] => {
  const filledData: GraphDataItem[] = [];
  if (graphDataArray.length === 0) {
    return filledData;
  }

  // Convert array to map for easier access
  const dataMap = new Map<string, GraphDataItem>(
    graphDataArray
      .sort((a, b) => (a.time < b.time ? -1 : 1))
      .map((item) => [item.time, item])
  );

  // Get the first and last dates in the array
  const startDate = new Date(graphDataArray[0].time);
  const endDate = new Date(graphDataArray[graphDataArray.length - 1].time);

  // Iterate through each date from startDate to endDate
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    const day = currentDate.getDate();

    const dateString = `${year}-${(month + 1).toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}`; // Get YYYY-MM-DD format

    // If the date is missing in dataMap, add it with a null value
    if (!dataMap.has(dateString)) {
      filledData.push({ time: dateString });
    } else {
      filledData.push(dataMap.get(dateString)!); // Add existing data (non-null assertion)
    }

    // Move to the next day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return filledData;
};

export const getLastXDays = (
  graphDataArray: GraphDataItem[],
  days: number
): GraphDataItem[] => {
  if (graphDataArray.length === 0 || days <= 0) {
    return [];
  }

  // Get the last date in the dataset
  const endDate = new Date(graphDataArray[graphDataArray.length - 1].time);
  const startDate = new Date(endDate);
  startDate.setDate(endDate.getDate() - (days - 1)); // Subtract X-1 days to include today

  // Filter data within the range
  return graphDataArray.filter((item) => {
    const itemDate = new Date(item.time);
    return itemDate >= startDate && itemDate <= endDate;
  });
};
