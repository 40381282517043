import LayoutMain from "layouts/LayoutMain";
import PageHeader from "components/PageHeader";
import WeightGraph from "components/WeightGraph";
import WeightHistory from "components/WeightHistory";
import styled from "styled-components";
import { useGlobalStorage } from "context/GlobalStorage";

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 14px;
  padding-bottom: 25vh;
`;

const WeightPage = () => {
  const { data } = useGlobalStorage();

  //
  // RENDER
  //
  return (
    <LayoutMain>
      <PageHeader title={`Váha`} />

      <Content>
        <WeightGraph data={data} />
        <WeightHistory data={data} />
      </Content>
    </LayoutMain>
  );
};

export default WeightPage;
