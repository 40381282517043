/* eslint-disable react-hooks/exhaustive-deps */

import { DayType, ExerciseType } from "types";
import {
  getExerciseCompletedSetCount,
  getExerciseIsCompleted,
  getWorkoutProgress,
} from "utils";
import { useEffect, useRef, useState } from "react";

import Button from "components/Button";
import CheckContainer from "components/CheckContainer";
import ExerciseEditor from "components/ExerciseEditor";
import ExerciseSet from "./ExerciseSet";
import { FaPersonRunning } from "react-icons/fa6";
import { IoIosFitness } from "react-icons/io";
import { MdModeEdit } from "react-icons/md";
import styled from "styled-components";
import { useGlobalStorage } from "context/GlobalStorage";

export const EXERCISE_OPEN_DURATION_SEC = 0.3;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
  padding: 10px;
  padding-right: 0px;
  padding-bottom: 60px;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;

  font-size: 14px;
  line-height: 1.4;
`;

const InfoText = styled.div`
  padding: 4px;
  color: ${(props) => props.theme.colors.text}88;
  white-space: pre-wrap;
`;

const ExerciseSets = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 5px;
`;

const InfoSection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5px;
`;

const Pause = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  padding: 4px 4px;
  min-height: 30px;
`;

const BubbleText = styled.div`
  color: ${(props) => props.theme.colors.text}88;

  strong {
    font-weight: 400;
    color: ${(props) => props.theme.colors.text}CC;
  }
`;

const Exercise = (props: {
  workoutId: string;
  workoutIsComplete: boolean;
  day: DayType;
  exerciseIndex: number;
  exercise: ExerciseType;
  forceOpen: boolean;
  onOpenChange: () => void;
  onCompleted: () => void;
}) => {
  const {
    workoutId,
    workoutIsComplete,
    day,
    exerciseIndex,
    exercise,
    forceOpen,
    onOpenChange,
    onCompleted,
  } = props;

  const { fns } = useGlobalStorage();

  const [isOpen, setIsOpen] = useState(false);
  const [isCompleted, setIsCompleted] = useState(
    getExerciseIsCompleted(exercise)
  );

  const prevCompletedCountRef = useRef(getExerciseCompletedSetCount(exercise));
  const prevIsCompletedRef = useRef(isCompleted);
  const isTotalCompleted = getWorkoutProgress(day) === 1;

  //
  // IS OPEN
  //
  useEffect(() => {
    onOpenChange();
  }, [isOpen]);

  useEffect(() => {
    if (forceOpen) {
      setIsOpen(true);
    }
  }, [forceOpen]);

  //
  // IS COMPLETED
  //
  useEffect(() => {
    if (isCompleted && !prevIsCompletedRef.current) {
      setIsOpen(false);
      onCompleted();
    }
    prevIsCompletedRef.current = isCompleted; // Update the ref after the effect
  }, [isCompleted]);

  useEffect(() => {
    const newIsCompleted = getExerciseIsCompleted(exercise);
    const newCompletedSetCount = getExerciseCompletedSetCount(exercise);

    if (
      newIsCompleted ||
      newCompletedSetCount < prevCompletedCountRef.current
    ) {
      fns.setTimer(undefined);
    } else if (newCompletedSetCount > prevCompletedCountRef.current) {
      fns.setTimer({
        durationMinutes:
          exercise.pauseBetweenSetsMinutes ||
          day.defaultPauseBetweenSetsMinutes,
      });
    }

    setIsCompleted(newIsCompleted);
    prevCompletedCountRef.current = newCompletedSetCount;
  }, [exercise]);

  //
  // EDITOR
  //
  const editorPopUpId = `${workoutId}/${day.id}/${exerciseIndex}/editor`;

  const onEdit = () => {
    fns.showPopUp({
      id: editorPopUpId,
      title: "Upravit",
      noPadding: true,
      content: (
        <ExerciseEditor
          workoutId={workoutId}
          day={day}
          exercise={exercise}
          exerciseIndex={exerciseIndex}
          onSave={() => fns.closePopUp(editorPopUpId)}
        />
      ),
    });
  };

  //
  // RENDER
  //
  return (
    <CheckContainer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      index={exerciseIndex}
      name={exercise.name}
      icon={exercise.isCardio ? FaPersonRunning : IoIosFitness}
      isTotalCompleted={isTotalCompleted}
      isCompleted={isCompleted}
      openDurationSec={EXERCISE_OPEN_DURATION_SEC}
      opacity={isCompleted && !workoutIsComplete ? 0.4 : 1}
    >
      <Content>
        <Info>
          <InfoSection>
            <Pause>
              {exercise.sets?.length > 1 ? (
                <BubbleText>
                  {`pauza: `}
                  <strong>{`${
                    exercise.pauseBetweenSetsMinutes ||
                    day.defaultPauseBetweenSetsMinutes
                  } min`}</strong>
                </BubbleText>
              ) : null}
            </Pause>

            <Button
              icon={MdModeEdit}
              label="upravit"
              onClick={onEdit}
              small
              faded
            />
          </InfoSection>

          {exercise.text ? <InfoText>{exercise.text}</InfoText> : null}
        </Info>

        <ExerciseSets>
          {exercise.sets?.map((set, i) => (
            <ExerciseSet
              key={i}
              workoutId={workoutId}
              day={day}
              exerciseIndex={exerciseIndex}
              exercise={exercise}
              setIndex={i}
              set={set}
            />
          ))}
        </ExerciseSets>
      </Content>
    </CheckContainer>
  );
};

export default Exercise;
