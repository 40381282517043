import { IoFitness } from "react-icons/io5";
import LayoutMain from "layouts/LayoutMain";
import LinkBox from "components/LinkBox";
import { LuHistory } from "react-icons/lu";
import PageHeader from "components/PageHeader";
import { PublicPageSlugs } from "pages-public";
import { RiRestaurant2Line } from "react-icons/ri";
import { getFormattedDate } from "utils";
import styled from "styled-components";
import { useGlobalStorage } from "context/GlobalStorage";

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 10px;
`;

const SectionTitle = styled.div`
  flex: 1;
  color: ${(props) => props.theme.colors.text}AA;
  font-size: 17px;
  font-weight: 400;

  margin-top: 24px;
`;

const HistoryLabel = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  color: ${(props) => props.theme.colors.text}AA;
  font-size: 14px;
  font-weight: 400;

  & > div:first-child {
    min-width: 115px;
  }
  & > div:last-child {
    display: flex;
    align-items: center;
    gap: 15px;

    & > div:first-child {
      opacity: 0.5;
    }
    & > div:last-child {
      min-width: 115px;
    }
  }
`;

const OverviewsPage = () => {
  const { data } = useGlobalStorage();

  //
  // RENDER
  //
  return (
    <LayoutMain>
      <PageHeader title={`Přehledy`} />

      <Content>
        <LinkBox
          label={"Přehled jídel"}
          icon={RiRestaurant2Line}
          to={"/" + PublicPageSlugs.publicMealsCurrent}
          publicLink
          small
        />
        <LinkBox
          label={"Aktuální přehled"}
          icon={IoFitness}
          to={"/" + PublicPageSlugs.publicDataPreviewCurrent}
          publicLink
          small
        />

        <SectionTitle>Historie:</SectionTitle>

        {data?.history?.map((h, i) => (
          <LinkBox
            key={i}
            label={
              <HistoryLabel>
                <div>{`${getFormattedDate(h.dates.start)}`}</div>
                <div>
                  <div>→</div>
                  <div>{`${getFormattedDate(h.dates.end)}`}</div>
                </div>
              </HistoryLabel>
            }
            icon={LuHistory}
            publicLink
            small
            to={
              "/" +
              PublicPageSlugs.publicDataPreview.replace(
                ":googleFileId",
                h.googleFileId
              )
            }
          />
        ))}
      </Content>
    </LayoutMain>
  );
};

export default OverviewsPage;
