import { DataType, WeightHistoryItemType } from "types";
import styled, { css } from "styled-components";

import LinkBox from "components/LinkBox";
import WeightEditor from "components/WeightEditor";
import { getFormattedDate } from "utils";
import { useGlobalStorage } from "context/GlobalStorage";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 5px;

  .weekday {
    opacity: 0.4;
    margin-right: 8px;
  }
`;

const LinkBoxLabel = styled.div<{ $isSickDay?: boolean }>`
  flex: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 10px;

  & > div:first-child {
    ${(props) =>
      props.$isSickDay
        ? css`
            color: ${(props) => props.theme.colors.warning};
          `
        : null}
  }
  & > div:last-child {
  }
`;

const WeightHistory = (props: { data: DataType; readOnly?: boolean }) => {
  const { data, readOnly } = props;
  const { fns } = useGlobalStorage();

  //
  // UTILS
  //
  const editWeight = (params?: {
    prevWeight?: WeightHistoryItemType;
    prevIndex?: number;
  }) => {
    const { prevWeight, prevIndex } = params || {};

    const weightPopUpId = "weight-popup";

    fns.showPopUp({
      id: weightPopUpId,
      title: prevWeight ? "Upravit váhu" : "Přidat váhu",
      noPadding: true,
      content: (
        <WeightEditor
          weight={prevWeight?.weightKg ?? 0}
          date={prevWeight?.date}
          isSickDay={prevWeight?.isSickDay}
          index={prevIndex}
          popUpId={weightPopUpId}
          onSave={({ weight, date, isSickDay }) => {
            const newWeight: WeightHistoryItemType = {
              weightKg: weight,
              date,
              isSickDay,
            };
            let updatedWeights: WeightHistoryItemType[] =
              data.weightHistory?.weights || [];

            if (prevWeight) {
              updatedWeights[prevIndex] = newWeight;
            } else {
              updatedWeights.push(newWeight);
            }

            updatedWeights.sort((a, b) => (a.date < b.date ? 1 : -1));

            fns.updateWeightHistory({
              updatedWeights,
            });
            fns.closePopUp(weightPopUpId);
          }}
        />
      ),
    });
  };
  //
  // RENDER
  //
  return (
    <Wrapper>
      {readOnly ? null : (
        <LinkBox
          label={`Zadat novou váhu`}
          small
          progressData={{
            weight: 0,
          }}
          onClick={() => editWeight()}
        />
      )}

      {data.weightHistory?.weights
        ?.sort((a, b) => (a.date < b.date ? 1 : -1))
        .map((w, i) => {
          return (
            <LinkBox
              key={i}
              label={
                <LinkBoxLabel $isSickDay={w.isSickDay}>
                  <div>{`${w.weightKg} kg`}</div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: getFormattedDate(w.date, {
                        showWeekday: true,
                      }),
                    }}
                  ></div>
                </LinkBoxLabel>
              }
              small
              progressData={{
                weight: 1,
              }}
              onClick={
                readOnly
                  ? undefined
                  : () => editWeight({ prevWeight: w, prevIndex: i })
              }
            />
          );
        })}
    </Wrapper>
  );
};

export default WeightHistory;
