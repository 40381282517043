export type DataType = {
  workoutPlans: WorkoutPlanType[];

  history?: HistoryType[];
  weightHistory?: WeightHistoryType;
};

export type HistoryType = {
  googleFileId: string;
  dates?: WorkoutPlanDates;
};

export type WeightHistoryItemType = {
  weightKg: number;
  date: string;
  isSickDay?: boolean;
};

export type WeightHistoryType = {
  weights: WeightHistoryItemType[];
};

export type WorkoutPlanType = {
  id: string;
  text: string;
  days: DayType[];
  weight?: WeightType;
  dates?: WorkoutPlanDates;
  mealSuggestions?: MealSuggestion[];
  files?: {
    categories?: FileCategory[];
    name: string;
    link: string;
  }[];
};

export enum FileCategory {
  "mealPlan" = "meal-plan",
}

export type WorkoutPlanDates = {
  start?: string;
  end?: string;
};

export type WeightType = {
  startKg?: number;
  endKg?: number;
};

export type DayType = {
  id: string;
  isSickDay?: boolean;
  name: string;
  text?: string;
  defaultPauseBetweenSetsMinutes: number;
  exercisesText?: string;
  exercises: ExerciseType[];
  meals: MealType[];
  supplements: SupplementType[];
};

export type ExerciseType = {
  name: string;
  text?: string;
  isCardio?: boolean;
  pauseBetweenSetsMinutes?: number;
  sets: ExerciseSetType[];
};

export type ExerciseSetType = {
  value?: string;
  repCount?: number;
  durationMinutes?: number;
  isCompleted?: boolean;
};

export enum TimelineItemEnum {
  "exercise" = "exercise",
  "exerciseSet" = "exerciseSet",
}

export type MealSuggestion = {
  mealIds: string[];
  suggestions: string[];
};

export type MealType = {
  id?: string;
  name: string;
  text?: string;
  options: MealOptionType[];
  customOption?: CustomMealOptionType;
};

export type MealOptionType = {
  ingredients: string[];
  isCompleted?: boolean;
};

export type CustomMealOptionType = {
  text: string;
  isCompleted?: boolean;
};

export type SupplementType = {
  id?: string;
  name: string;
  text?: string;
  options: SupplementOptionType[];
  customOption?: CustomSupplementOptionType;
};

export type SupplementOptionType = {
  ingredients: string[];
  isCompleted?: boolean;
};

export type CustomSupplementOptionType = {
  text: string;
  isCompleted?: boolean;
};

export type TimerType = {
  durationMinutes: number;
};

export enum PopUpTypeEnum {
  "info" = "info",
  "error" = "error",
}

export type PopUpType = {
  id: string;
  type?: PopUpTypeEnum;
  title?: string;
  content?: any;
  noPadding?: boolean;
};

export type GraphDataItem = {
  time: string; // Format: "YYYY-MM-DD"
  value?: number;
};
