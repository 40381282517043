import DayLink from "components/DayLink";
import LinkBox from "components/LinkBox";
import PageHeader from "components/PageHeader";
import { PageSlugs } from "pages";
import { WorkoutPlanType } from "types";
import { getFormattedDate } from "utils";
import styled from "styled-components";

const Wrapper = styled.div`
  width: 100%;
  margin: auto;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 6px;

  margin-bottom: 40px;
`;

const Divider = styled.div`
  margin-bottom: 20px;
`;

const WorkoutPlan = (props: { dataWorkoutPlan: WorkoutPlanType }) => {
  const { dataWorkoutPlan } = props;

  return (
    <Wrapper>
      <PageHeader
        title={`${getFormattedDate(
          dataWorkoutPlan.dates?.start
        )}  -  ${getFormattedDate(dataWorkoutPlan.dates?.end)}`}
        text={dataWorkoutPlan?.text}
      />

      <LinkBox
        label={"Váha"}
        progressData={{
          weight: 1,
        }}
        to={`${PageSlugs.weight}`}
      />

      <Divider />

      {/*  <WeightLink
        dataWorkoutPlan={dataWorkoutPlan}
        weightType={WeightTypeEnum.start}
      /> */}
      {dataWorkoutPlan?.days?.map((day, i) => {
        return <DayLink key={i} workoutId={dataWorkoutPlan.id} day={day} />;
      })}
      {/* <WeightLink
        dataWorkoutPlan={dataWorkoutPlan}
        weightType={WeightTypeEnum.end}
      /> */}
    </Wrapper>
  );
};

export default WorkoutPlan;
