/* eslint-disable react-hooks/exhaustive-deps */

import styled, { css } from "styled-components";
import { useEffect, useRef } from "react";

import { IoMdCheckmark } from "react-icons/io";

const MIN_ITEM_HEIGHT = "54px";
const BORDER_RADIUS = "4px";

const Wrapper = styled.div<{ $isFaded: boolean }>`
  --color-bg-todo: ${(props) => props.theme.colors.text}11;
  --color-bg-filled: ${(props) => props.theme.colors.text}08;
  --color-bg-done: ${(props) => props.theme.colors.main}11;

  flex: 1;
  display: flex;
  align-items: stretch;

  * {
    font-size: 18px;
    line-height: 100%;
  }

  ${(props) =>
    props.$isFaded
      ? css`
          opacity: 0.5;
        `
      : css``}
`;

const Items = styled.div<{ $isCompleted: boolean }>`
  margin: 0;

  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: 8px;

  border-radius: ${BORDER_RADIUS};
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;

  padding: 14px 14px;

  ${(props) =>
    props.$isCompleted
      ? css`
          background: var(--color-bg-done);
        `
      : css`
          background: var(--color-bg-filled);
        `}
`;

const Item = styled.div`
  color: ${(props) => props.theme.colors.text}AA;
  font-size: 15px;
  line-height: 150%;
  white-space: pre-wrap;
`;

const Icon = styled.div<{ $isCompleted: boolean }>`
  cursor: pointer;
  min-width: ${MIN_ITEM_HEIGHT};
  min-height: ${MIN_ITEM_HEIGHT};

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: ${BORDER_RADIUS};
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;

  svg {
    font-size: 24px;
    margin-bottom: -2px;
  }

  ${(props) =>
    props.$isCompleted
      ? css`
          color: ${props.theme.colors.main};
          background: var(--color-bg-done);
        `
      : css`
          color: ${props.theme.colors.background};
          background: var(--color-bg-todo);
        `}
`;

const TextArea = styled.textarea<{ $isCompleted: boolean }>`
  flex: 1;
  box-sizing: border-box;
  outline: none;
  border: none;
  resize: none;
  overflow: hidden;

  padding: 10px 15px;
  color: ${(props) => props.theme.colors.text}AA;

  font-family: inherit;
  font-size: 15px;
  font-weight: 300;
  line-height: 150%;

  ${(props) =>
    props.$isCompleted
      ? css`
          background: var(--color-bg-done);
        `
      : css`
          background: var(--color-bg-filled);
        `}
`;

const CheckItem = (props: {
  items?: string[];
  isCompleted?: boolean;
  isFaded?: boolean;
  onCheckClick: () => void;
  containerIsOpen?: boolean;

  customText?: {
    placeholder?: string;
    value: string;
    onChange?: (v: string) => void;
  };
}) => {
  const {
    items,
    isCompleted,
    isFaded,
    onCheckClick,
    customText,
    containerIsOpen,
  } = props;

  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const updateHeight = () => {
    if (textAreaRef.current && customText?.value !== undefined) {
      const textArea = textAreaRef.current;
      textArea.style.height = "auto";
      textArea.style.height = `${textArea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    updateHeight();
  }, [customText?.value]);

  useEffect(() => {
    if (containerIsOpen) {
      setTimeout(updateHeight, 1);
    }
  }, [containerIsOpen]);

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    customText?.onChange(e.target.value);
  };

  return (
    <Wrapper $isFaded={isFaded}>
      {customText ? (
        <TextArea
          ref={textAreaRef} // Attach the ref to the textarea
          $isCompleted={isCompleted}
          value={customText.value || ""}
          onInput={handleInput}
          placeholder={customText.placeholder}
          disabled={!customText?.onChange}
        />
      ) : null}

      {items?.length ? (
        <Items $isCompleted={isCompleted}>
          {items?.map((item, i) => (
            <Item key={i}>{item}</Item>
          ))}
        </Items>
      ) : null}

      <Icon $isCompleted={isCompleted} onClick={onCheckClick}>
        <IoMdCheckmark />
      </Icon>
    </Wrapper>
  );
};

export default CheckItem;
