/* eslint-disable react-hooks/exhaustive-deps */

import {
  LastPriceAnimationMode,
  LineSeries,
  LineSeriesPartialOptions,
  LineStyle,
  LineType,
  createChart,
} from "lightweight-charts";
import { getFormattedDate, getLastXDays, sortAndFillDateGapsAsc } from "utils";
import { useEffect, useMemo, useRef, useState } from "react";

import Button from "components/Button";
import { DataType } from "types";
import styled from "styled-components";
import { theme } from "styles";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 14px;
`;

const Buttons = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 5px;
`;

// Lightweight Charts™ Example: Range switcher
// https://tradingview.github.io/lightweight-charts/tutorials/demos/range-switcher

enum GRAPH_TIME_MODE {
  "all" = "all",
  "week" = "week",
  "twoWeeks" = "twoWeeks",
}

const GRAPH_TIME_MODES: Record<GRAPH_TIME_MODE, string> = {
  [GRAPH_TIME_MODE.week]: "7 dní",
  [GRAPH_TIME_MODE.twoWeeks]: "14 dní",
  [GRAPH_TIME_MODE.all]: "vše",
};

const WeightGraph = (props: { data: DataType }) => {
  const { data } = props;

  //
  // ACTIVE TIME MODE
  //
  const setChartIntervalRef = useRef<(interval) => void>();

  const [activeTimeMode, setActiveTimeMode] = useState<GRAPH_TIME_MODE>(
    GRAPH_TIME_MODE.twoWeeks
  );

  useEffect(() => {
    setChartIntervalRef?.current?.(activeTimeMode);
  }, [activeTimeMode]);

  //
  // GRAPH DATA
  //
  const { weekData, twoWeeksData, allData } = useMemo(() => {
    const graphDataArray = (data.weightHistory?.weights || [])?.map((w) => {
      return {
        time: w.date,
        value: w.weightKg,
      };
    });

    const allData = sortAndFillDateGapsAsc(graphDataArray);
    const weekData = getLastXDays(allData, 8);
    const twoWeeksData = getLastXDays(allData, 15);

    console.log(graphDataArray);
    console.log(allData);
    console.log(weekData);
    console.log(twoWeeksData);

    return { weekData, twoWeeksData, allData };
  }, [data.weightHistory]);

  const seriesesData = new Map([
    [GRAPH_TIME_MODE.all, allData],
    [GRAPH_TIME_MODE.week, weekData],
    [GRAPH_TIME_MODE.twoWeeks, twoWeeksData],
  ]);

  //
  // GRAPH SETTINGS
  //
  const chartOptions = {
    /// https://tradingview.github.io/lightweight-charts/docs/api/interfaces/ChartOptionsImpl
    localization: {
      locale: "cs",
      dateFormat: "dd MMMM yyyy",
      timeFormatter: (date) => `${getFormattedDate(date)}`,
      priceFormatter: (p) => `${p.toFixed(1)} kg`,
    },
    height: 200,
    layout: {
      textColor: `${theme.colors.text}44`,
      background: { color: "transparent" },
      attributionLogo: false,
    },
    autoSize: true,
    rightPriceScale: {
      scaleMargins: {
        top: 0.1,
        bottom: 0.2,
      },
      borderColor: `${theme.colors.text}44`,
      ticksVisible: false,
      autoScale: true,
      alignLabels: true,
      entireTextOnly: true,
    },
    timeScale: {
      fixLeftEdge: true, // Prevent scrolling to the left of the first bar.
      fixRightEdge: true, // Prevent scrolling to the left of the first bar.
      borderVisible: true,
      borderColor: `${theme.colors.text}44`,
      ticksVisible: false,
      tickMarkMaxCharacterLength: 2,
      uniformDistribution: true,
    },
    crosshair: {
      // https://tradingview.github.io/lightweight-charts/docs/api/interfaces/CrosshairOptions
    },
    grid: {
      vertLines: {
        color: `${theme.colors.text}55`,
        style: LineStyle.SparseDotted,
      },
      horzLines: {
        color: `${theme.colors.text}55`,
        style: LineStyle.SparseDotted,
      },
    },
    handleScroll: {
      // https://tradingview.github.io/lightweight-charts/docs/api/interfaces/HandleScrollOptions
    },
  };

  const intervalColors = {
    recent: theme.colors.main,
    all: theme.colors.main,
  };

  const lineOptions: LineSeriesPartialOptions = {
    // https://tradingview.github.io/lightweight-charts/docs/api/interfaces/LineStyleOptions
    color: intervalColors["all"],
    lineVisible: true,
    lineStyle: LineStyle.Solid,
    lineWidth: 1,
    lineType: LineType.Curved, // LineType.WithSteps,
    pointMarkersVisible: true,
    pointMarkersRadius: 3,
    crosshairMarkerVisible: true,
    crosshairMarkerRadius: 4,
    crosshairMarkerBorderWidth: 10,
    crosshairMarkerBorderColor: theme.colors.background,
    crosshairMarkerBackgroundColor: theme.colors.main,
    lastPriceAnimation: LastPriceAnimationMode.Continuous,
  };

  //
  // INIT GRAPH
  //
  useEffect(() => {
    const container = document.getElementById("graph-container");
    container.innerHTML = "";

    const chart = createChart(container, chartOptions);

    const setChartInterval = (interval) => {
      lineSeries.setData(seriesesData.get(interval));
      lineSeries.applyOptions({
        color: intervalColors[interval],
      });
      chart.timeScale().fitContent();
    };

    setChartIntervalRef.current = setChartInterval;

    const lineSeries = chart.addSeries(LineSeries, lineOptions);

    setChartInterval(activeTimeMode);
  }, [allData]);

  //
  // RENDER
  //
  return (
    <Wrapper>
      <Buttons>
        {Object.entries(GRAPH_TIME_MODES).map(([mode, label]) => {
          return (
            <Button
              key={mode}
              small
              label={`${label}`}
              highlighted={activeTimeMode === mode}
              onClick={() => setActiveTimeMode(mode as GRAPH_TIME_MODE)}
            />
          );
        })}
      </Buttons>

      <div id="graph-container" />
    </Wrapper>
  );
};

export default WeightGraph;
