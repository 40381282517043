import BooleanInput from "components/Inputs/BooleanInput";
import Button from "components/Button";
import { FaRegTrashAlt } from "react-icons/fa";
import NumberInput from "components/Inputs/NumberInput";
import { RiSaveFill } from "react-icons/ri";
import TextInput from "components/Inputs/TextInput";
import { getCurrentDate } from "utils";
import styled from "styled-components";
import { useGlobalStorage } from "context/GlobalStorage";
import { useState } from "react";

export const EXERCISE_OPEN_DURATION_SEC = 0.3;

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const Scroll = styled.div`
  flex: 1;

  overflow-x: hidden;
  overflow-y: auto;

  padding: 12px;
  padding-top: 20px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
`;

const Footer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 16px 12px;

  & > *:last-child {
    margin-left: auto;
  }
`;

const WeightEditor = (props: {
  index?: number;
  weight: number;
  date?: string;
  isSickDay?: boolean;
  popUpId?: string;
  onSave: (params: {
    weight: number;
    date?: string;
    isSickDay?: boolean;
  }) => void;
}) => {
  const { onSave } = props;

  const { fns } = useGlobalStorage();
  const [weight, setWeight] = useState<number>(props.weight ?? 0);
  const [date, setDate] = useState<string>(props.date ?? getCurrentDate());
  const [isSickDay, setIsSickDay] = useState<boolean>(props.isSickDay);

  //
  // DATA
  //
  const saveChanges = () => {
    onSave?.({ weight, date, isSickDay });
  };

  //
  // RENDER
  //
  return (
    <Wrapper>
      <Scroll>
        <NumberInput
          label={`Váha (kg)`}
          value={weight}
          onChange={setWeight}
          step="0,1"
        />

        <TextInput
          label={`Datum (TODO - date picker)`}
          value={date}
          onChange={setDate}
        />

        <BooleanInput
          label={`Nemoc`}
          value={isSickDay}
          onChange={setIsSickDay}
        />
      </Scroll>

      <Footer>
        {props.index !== undefined ? (
          <Button
            label="Smazat"
            icon={FaRegTrashAlt}
            warning
            onClick={() =>
              fns.deleteFromWeightHistory({
                deleteWeight: {
                  weightKg: props.weight,
                  date: props.date,
                  isSickDay: props.isSickDay,
                },
                deleteIndex: props.index,
                closePopUpId: props.popUpId,
              })
            }
          />
        ) : null}

        <Button label="Uložit" icon={RiSaveFill} onClick={saveChanges} />
      </Footer>
    </Wrapper>
  );
};

export default WeightEditor;
